var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"md":"7"}},[_c('h4',[_vm._v(" QRIS Pending")])]),_c('CCol',{attrs:{"md":"5"}},[_c('CInput',{attrs:{"label":"Search","placeholder":"Type to Search","horizontal":""},on:{"change":_vm.getFilterData},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)],1),_c('br'),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"12"}},[(_vm.seen)?_c('CAlert',{attrs:{"color":_vm.color,"id":"alert"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)],1),_c('div',{staticClass:"text-center"},[(_vm.spinner)?_c('b-spinner',{attrs:{"variant":"success","label":"Spinning"}}):_vm._e()],1),(_vm.formReason)?_c('b-card-group',{staticClass:"mb-3",staticStyle:{"width":"60%"},attrs:{"deck":""}},[_c('b-card',{attrs:{"border-variant":"dark","header":"QRIS Reason Action"}},[_c('b-card-text',[_c('CTextarea',{attrs:{"label":"Reason","placeholder":"Type Reason...","maxlength":"180","description":"Maximum 180 characters","horizontal":"","rows":"3"},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),_c('b-button-group',[_c('b-button',{on:{"click":function($event){return _vm.actionCancel()}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.ActionSubmit()}}},[_vm._v("Submit")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"table-responsive scroll-r"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"sort-by":"createDate","sort-desc":true,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(createDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(item.createDate))+" ")]}},{key:"cell(exchangeRate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.exchangeRate))+" ")]}},{key:"cell(idrAmount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.idrAmount))+" ")]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.amount))+" ")]}},{key:"cell(idrTipAmount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.idrTipAmount))+" ")]}},{key:"cell(storeBalance)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.storeBalance))+" ")]}},{key:"cell(transactionFee)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.transactionFee))+" ")]}},{key:"cell(userDebitedAmount)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNum")(item.userDebitedAmount))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"info","text":"Actions","size":"md"}},[_c('router-link',{attrs:{"to":"#","exact":"","tag":"b-dropdown-item"},nativeOn:{"click":function($event){return _vm.showFormAction(item.transactionRef,'cancel')}}},[_vm._v(" Cancel ")]),_c('router-link',{attrs:{"to":"#","exact":"","tag":"b-dropdown-item"},nativeOn:{"click":function($event){return _vm.showFormAction(item.transactionRef,'complete')}}},[_vm._v(" Complete ")])],1)]}}]),model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}})],1),_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-pagination',{attrs:{"size":"md","total-rows":this.items.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-select',{staticStyle:{"width":"100px"},attrs:{"options":[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }